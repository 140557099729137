import * as Sentry from '@sentry/react';
import { isPlainObject, merge } from 'lodash';
import { SubmissionError } from 'redux-form';

import { HVSentry } from '$Utils/sentry-utils';

export { SubmissionError };

/**
 * @param {object} options
 */
export const setSentryScope = options => {
    if (!window.use_sentry) return;
    Object.keys(options).forEach(key => {
        Sentry.configureScope(scope => {
            scope.setExtra(key, options[key]);
        });
    });
};

/**
 *
 * @param {string} tagName The name of the tag to show in Sentry's UI
 * @param {string} tagValue The value of the tag
 */
export function setSentryTag(tagName, tagValue) {
    if (!!window.use_sentry) {
        Sentry.configureScope(function (scope) {
            scope.setTag(tagName, tagValue);
        });
    }
}

/**
 * Checks if an async action has failed by looking for the string FAILURE
 * @deprecated Unecessary without redux / redux-form
 * @param {object} data
 * @returns boolean
 */
export const isFailure = data => {
    if (!data || !data.type) {
        throw new Error("Response is not an action; did you forget 'await'?");
    }
    return data.type.includes('FAILURE');
};

/**
 * Formats an error object for use on the front-end.
 * @deprecated Unecessary without redux / redux-form
 * @param {Object} data - complete API response as returned by Redux.
 * @return {Object} An error message object readable by redux-form and other React-related applications and components
 */
export const formatErrorObject = data => {
    //pass entire API response
    const genericError = {
        _error: 'Sorry, an error occurred. Please try again later.'
    };

    // If no valid API response, just throw generic
    if (!data || !data.error) {
        HVSentry.sendExceptionToReactSentry(
            'Response contains either no data or no error. '
        );
        return genericError;
    }
    const { metadata: _metadata = {} } = data;
    const { response = null, status, statusText, responseURL } = data.error;

    if (status) _metadata.status = status;
    if (statusText) _metadata.statusText = statusText;
    if (responseURL) _metadata.url = responseURL;

    // Sometimes 500s and 400s don't return a text response,
    // but we still want the metadata on the failure type
    const hasResponse = !!response;
    // Is the response a string?
    // TO DO: use lodash.isString going forward and
    const isString = typeof response === 'string' || response instanceof String;
    let error = {};
    try {
        error = isString
            ? JSON.parse(response)
            : hasResponse
            ? response
            : genericError;
    } catch (e) {
        // if it can't be parsed, throw a generic error
        error = genericError;
        //capture the weird response for troubleshooting
        _metadata.response = response
            ? response.toString()
            : 'No response from server.';
        HVSentry.sendMessageToDjangoSentry(_metadata);
    }
    // Some error keys we frequently use in django:
    const { __all__, non_field_errors, detail } = error;
    // redux-form expects the key _error.
    // If we are already getting _error from the server, leave it be.
    // Otherwise, give redux-form what it wants.
    // It will be displayed above the button for all the forms using
    // the `FormButton` component, or can be placed in a component
    // using `this.props.error`.

    // If the _error key is empty, see if there are other top level errors
    // And assign it properly
    if (!error._error) {
        error._error = __all__ || non_field_errors || detail;
    }

    // Get rid of those bad keys
    if (__all__) delete error.__all__;
    if (non_field_errors) delete error.non_field_errors;
    if (detail) delete error.detail;

    // Also make a copy and get rid of other top level keys
    let errorWithoutType = merge({}, error);

    if (errorWithoutType.type) delete errorWithoutType.type;

    // If there are field errors left (e.g. additional keys), just show those
    if (Object.keys(errorWithoutType).length == 1 && !error._error) {
        // If only error._error is left and that is undefined,
        // Set that key to generic
        error._error = genericError._error;
    }

    // Return the error and additional values for debugging
    return Object.assign({}, error, {
        _metadata
    });
};

/**
 * Curried function to throw an error of a specific type
 * @deprecated Unecessary without redux / redux-form
 * @param {Class} ErrorClass - An class that extends Error.
 * @return {function} A function that takes an api response as a param.
 */
export const throwError = ErrorClass => data => {
    // Right now failures are passed through Redux to the component
    // With a type including the string `FAILURE`

    if (!!data && !!data.type && !data.type.includes('FAILURE')) {
        // If it's successful, return out
        return data;
    }

    // If it's a failure, format the error
    const formattedErrorObj = formatErrorObject(data);
    //Throw it
    if (!ErrorClass) throw formattedErrorObj;
    throw new ErrorClass(formattedErrorObj);
};

/**
 * Throws a SubmissionError for a `redux-form` form.
 * @deprecated Don't use redux form; use formik instead
 * @param {Object} data - A complete API response as returned by Redux.
 */
export const checkIfFormErrorAndThrow = throwError(SubmissionError);

/**
 * @deprecated Unecessary without redux / redux-form
 */
export class ServerError extends Error {
    constructor(errorObject) {
        const { _metadata: metadata = {}, _error } = errorObject;

        const {
            type = 'Unknown Type,',
            url = 'Unknown URL',
            status = 'UnknownStatus',
            statusText
        } = metadata;

        const message = `[${status}: ${
            statusText || ''
        }] ${type} ${url} (Display text: ${_error})`;

        super(message);
        this.name = this.constructor.name;
        this.displayError = _error;
        this.status = status;
        this.statusText = statusText;
    }
}

Object.defineProperty(ServerError.prototype, 'toJSON', {
    value: function () {
        let alt = {};

        Object.getOwnPropertyNames(this).forEach(function (key) {
            alt[key] = this[key];
        }, this);

        return alt;
    },
    configurable: true,
    writable: true
});

/**
 * @deprecated
 * Throws a generic Server-based Error.
 * @param {Object} data - A complete API response as returned by Redux.
 */
export const checkIfServerErrorAndThrow = throwError(ServerError);

/**
 * DRF returns errors in the following format when there is more than one error per key:
 * [
 * { emr_id: 'EMR ID 37823 does not exist' },
 * { emr_id: 'EMR ID 23ff does not exist' }
 * ]
 * Which Formik cannot handle.
 * This modifies the output to the following:
 * {
 *    emr_id: 'EMR ID 37823 does not exist, EMR ID 23ff does not exist'
 * }
 * @param {array} errorsArray
 * @returns {object} An object with a unique key for every field, with multiple string error messages concatenated in a way Formik can handle
 */
export function formatFormikErrors(errorsArray, options = {}) {
    // force an array

    const { flattenArrays = false, joinArraysToString = false } = options;

    if (!Array.isArray(errorsArray)) {
        errorsArray = [errorsArray];
    }
    const merged = errorsArray.reduce((accum, obj) => {
        if (isPlainObject(obj)) {
            for (const key in obj) {
                accum[key] = accum[key]
                    ? [accum[key]].concat([obj[key]])
                    : obj[key];
                if (flattenArrays) {
                    accum[key] = accum[key].flat();
                }
                if (joinArraysToString) {
                    accum[key] = accum[key].join(', ');
                }
            }

            return accum;
        }
        if (Array.isArray(obj)) {
            return formatFormikErrors(obj);
        }
    }, {});
    return merged;
}
