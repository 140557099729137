import SingleColumn from '$ContainersLegacy/SingleColumn';

/**
 * Checks that cookies are enabled, and shows a warning if they are not.
 */

const CookieError = () => {
    return (
        <>
            {!navigator.cookieEnabled && (
                <SingleColumn center={true} marginBottom={48} marginTop={48}>
                    <p>You do not currently have cookies on.</p>
                    <p>
                        You must have cookies turned on to use Healthvana. Check
                        your settings before logging in. If you need assistance,
                        please use our{' '}
                        <a href="https://healthvana.com/contact/">
                            contact form
                        </a>
                        .
                    </p>
                </SingleColumn>
            )}
        </>
    );
};

export default CookieError;
