import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import styles from './Fields.module.less';

import { normalizeCSSID } from '$Validation/Normalizer';

/**
 *
 * @deprecated all new forms should use HVUI/FormikForm and HVUI/FormControl
 */
const WrapField = (Component, options = {}) => {
    const { isCheck, reduxForm, isPWCreate } = options;
    class Field extends React.Component {
        static defaultProps = {
            data: {},
            meta: { touched: false },
            labelCase: 'uppercase',
            hideLabel: false,
            hideError: false,
            showSuccess: false
        };

        state = {
            errorString: null
        };
        componentDidMount() {
            const metaObj = reduxForm ? this.props.meta : this.props;
            if (metaObj.error) this.formatError();
        }
        componentDidUpdate(prevProps) {
            const metaObj = reduxForm ? this.props.meta : this.props;
            const prevMetaObject = reduxForm ? prevProps.meta : prevProps;
            if (metaObj.error !== prevMetaObject.error) this.formatError();
        }

        showError = () => {
            const metaObj = reduxForm ? this.props.meta : this.props;
            const { errorOnChange } = this.props;
            const {
                error,
                touched = true,
                dirty = false,
                active,
                submitFailed
            } = metaObj;

            if (submitFailed && !!error) return true;
            if (!!errorOnChange) {
                //Show error on change, even if user is still typing
                return !!error && touched;
            } else {
                //Show error on blur
                return !!error && (touched || dirty) && !active;
            }
        };

        formatError = () => {
            //handles if error is a Promise (in the case of a dynamic import)
            const metaObj = reduxForm ? this.props.meta : this.props;

            const { error } = metaObj;

            if (Promise.resolve(error) == error) {
                error.then(errorText => {
                    if (errorText == null)
                        return this.setState({ errorString: null });
                    return this.setState({ errorString: errorText });
                });
            } else if (error) {
                return this.setState({ errorString: error });
            }
        };

        inputClassName = () => {
            const metaObj = reduxForm ? this.props.meta : this.props;
            const { valid } = metaObj;
            //Show an error
            if (this.showError() && !this.props.hideError) return 'input-error';
            //Show success
            if (this.props.showSuccess && valid) return 'input-complete';
            //For pristine, focused, incomplete: show normal styles
            return 'input';
        };

        render() {
            const {
                hideError,
                hideLabel,
                htmlLabel,
                placeholder,
                labelStyle,
                style,
                labelCase,
                help_text
            } = this.props;
            let { id } = this.props;

            //Depending on whether the field is being used with Redux form or not,
            //adjust how the props are passed in
            const inputObj = reduxForm ? this.props.input : this.props;
            const metaObj = reduxForm ? this.props.meta : this.props;
            const dataObj = reduxForm ? this.props.data : this.props;
            const { name, value, onChange, onFocus, onBlur } = inputObj;
            const { error, valid, touched } = metaObj;
            const { label, obscured, right_label } = dataObj;

            // DynamicField passes this down but not all of these are DFs
            if (!id) {
                id = normalizeCSSID(name);
            }

            const disabled = metaObj.disabled || this.props.disabled;

            const labelRender = (
                <label
                    className={
                        labelCase === 'uppercase'
                            ? styles['label']
                            : styles[`label-${labelCase}`]
                    }
                    htmlFor={id || name}
                    id={`label-${id || name}`}
                    // only include labelStyle prop if it exists
                    //  this formatting prevents style=undefined in storyshots
                    {...(labelStyle && { style: labelStyle })}
                >
                    {htmlLabel ? ReactHtmlParser(label) : label}
                    {!!right_label && (
                        <span className={styles['right-label']}>
                            {right_label}
                        </span>
                    )}
                </label>
            );

            return (
                <div
                    className={styles[isPWCreate ? 'field-pw' : 'field']}
                    id={'field-' + id || name}
                    data-validity={valid ? 'complete' : 'incomplete'}
                    style={style}
                >
                    {hideLabel || isCheck ? '' : labelRender}
                    <Component
                        {...this.props}
                        disabled={disabled}
                        id={id || name}
                        inputClassName={this.inputClassName()}
                        name={name}
                        obscured={obscured}
                        onChange={onChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        placeholder={placeholder}
                        value={value}
                        valid={valid}
                        touched={touched}
                    />
                    {isCheck && !hideLabel && labelRender}
                    {!hideError && (
                        <div className={styles['error']} id={`error-${name}`}>
                            {this.showError() && (
                                <span>{this.state.errorString}</span>
                            )}
                        </div>
                    )}
                    {help_text && (
                        <span className={styles['help-text']}>{help_text}</span>
                    )}
                </div>
            );
        }
    }
    return Field;
};
/**
 *
 * @deprecated all new forms should use HVUI/FormikForm and HVUI/FormControl
 */
const Wrapped = (Component, options) => WrapField(Component, options);

export default Wrapped;
