import { CALL_API } from '$ReduxLegacy/Middleware/API';

// =================== UNIVERSAL LOGIN
// Authenticates and Signs In Patients and Providers

export const UNIVERSALLOGIN_REQUEST = 'UNIVERSALLOGIN_REQUEST';
export const UNIVERSALLOGIN_SUCCESS = 'UNIVERSALLOGIN_SUCCESS';
export const UNIVERSALLOGIN_FAILURE = 'UNIVERSALLOGIN_FAILURE';

const postUniversalLogin = (username, password, next) => ({
    [CALL_API]: {
        types: [
            UNIVERSALLOGIN_REQUEST,
            UNIVERSALLOGIN_SUCCESS,
            UNIVERSALLOGIN_FAILURE
        ],
        endpoint: '/api/v2/sign-in/',
        schema: false,
        options: {
            type: 'POST',
            data: { username, password, next }
        }
    }
});

export const handleUniversalLogin = (username, password, next) => dispatch => {
    return dispatch(postUniversalLogin(username, password, next));
};
