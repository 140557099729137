// Single column is a Layout Container with a max-width of 540px

import styles from './index.module.less';

const Index = ({
    element = 'div',
    center,
    children,
    marginLeft,
    marginRight,
    marginTop,
    marginBottom,
    style
}) => {
    const centerStyle = center ? { textAlign: 'center' } : {};
    const margins = { marginLeft, marginRight, marginTop, marginBottom };
    const finalStyle = Object.assign(margins, centerStyle, style);
    const Element = element;
    return (
        <Element className={styles['container']} style={finalStyle}>
            {children}
        </Element>
    );
};

export default Index;
