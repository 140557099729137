import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import UniversalLogin from './UniversalLogin/UniversalLogin';

import { UnsupportedHeader } from '$HVUI';
import { apiMiddleware } from '$ReduxLegacy/Middleware/API';
import rootReducer from '$ReduxLegacy/Reducers/_Applications/UniversalLogin';
import configureStore from '$ReduxLegacy/Stores/configureStore';

const store = configureStore(rootReducer, {
    thunk: true,
    middleware: [apiMiddleware]
});

const container = document.getElementById('react-root');
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <UnsupportedHeader />
        <UniversalLogin />
    </Provider>
);
