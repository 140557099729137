const apiFactory = types => {
    if (!Array.isArray(types) || types.length !== 3) {
        throw new Error('Expected types to be an array of three elements.');
    }
    types.forEach(type => {
        if (type == null) {
            throw new Error(
                type +
                    ' is undefined or null; No types should be undefined. Check for typos.'
            );
        }
    });
    const [requestType, successType, failureType] = types;

    const api = (
        state = {
            fetching: false,
            lastFetch: null,
            failed: false,
            errorMessage: null
        },
        action
    ) => {
        switch (action.type) {
            case requestType:
                return Object.assign(state, {
                    fetching: true,
                    failed: false
                });
            case successType:
                return Object.assign(state, {
                    fetching: false,
                    lastFetch: new Date()
                });
            case failureType:
                if (!action.error) action.error = {};
                return Object.assign(state, {
                    fetching: false,
                    failed: true,
                    errorMessage:
                        action.error.statusText ||
                        action.error.message ||
                        action.error
                });
            default:
                return state;
        }
    };
    return api;
};

export default apiFactory;
