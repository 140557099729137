import React from 'react';
import { BiCheck, BiCog } from 'react-icons/bi';
import { MdDownload } from 'react-icons/md';
import { RiPencilFill } from 'react-icons/ri';
import { Link } from 'react-router-3';
import { Link as Link4 } from 'react-router-dom';

import styles from './Button.module.less';

import { Spinner } from '$HVUI';

/**
 * @deprecated
 */
export const elementTypes = ['a', 'button', 'input', 'Link'];

/**
 * @deprecated use $HVUI/Button or $HVUI/CallToAction
 */
export class Button extends React.Component {
    state = {};
    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        const {
            disabled = false,
            element = 'button',
            href,
            icon,
            id,
            kind = 'primary',
            loading = false,
            onClick,
            text,
            to,
            style,
            target,
            name = null,
            type,
            value
        } = this.props;

        const finalLoading = loading || this.state.loading;
        const finalDisabled = disabled || finalLoading;
        let finalStyle = style && Object.assign({}, style);
        //don't use custom background color if disabled
        if (finalDisabled && finalStyle && finalStyle.backgroundColor) {
            delete finalStyle.backgroundColor;
        }
        //If no text, use non-breaking space to preserve height.
        const finalText = text ? text : '\u00A0';
        const El = element.toLowerCase();
        const className = `button-${kind}${finalDisabled ? '-disabled' : ''}`;
        if (El === 'input')
            return (
                <input
                    id={id}
                    className={styles[className]}
                    type="submit"
                    name="submit"
                    value={finalText}
                    disabled={finalDisabled}
                />
            );

        const iconLib = {
            download: MdDownload,
            check: BiCheck,
            pencil: RiPencilFill,
            cog: BiCog
        };

        let iconEl = '';
        if (icon) {
            const DisplayIcon = iconLib[icon];
            iconEl = (
                <DisplayIcon
                    className={'Icon--' + icon}
                    style={{ height: '15px', width: '15px' }}
                />
            );
        }
        //React Router 3 Link
        if (El === 'link') {
            return (
                <Link
                    id={id}
                    className={styles[className]}
                    to={to}
                    style={finalStyle}
                >
                    {iconEl}
                    <span>{finalText}</span>
                </Link>
            );
        }

        //React Router 4+ Link
        if (El === 'link4') {
            return (
                <Link4
                    id={id}
                    className={styles[className]}
                    to={to}
                    style={finalStyle}
                >
                    <span>
                        {iconEl}
                        {finalText}
                    </span>
                </Link4>
            );
        }
        const spinnerSize = kind.includes('narrow') ? 15 : 20;
        const spinner = (
            <Spinner
                size={spinnerSize}
                color="var(--gray200)"
                style={{ margin: '-2px 4px' }}
                id={id || 'button'}
            />
        );
        return (
            <El
                id={id}
                className={styles[className]}
                onClick={e => {
                    if (finalDisabled || !onClick) return;
                    this.setState({ loading: true });
                    //this will work even if onClick doesn't return a Promise
                    Promise.resolve(onClick(e)).then(() => {
                        if (this._isMounted) this.setState({ loading: false });
                    });
                }}
                href={href}
                style={finalStyle}
                name={name}
                target={target}
                aria-busy={finalLoading ? true : false}
                disabled={finalDisabled}
                type={type}
                value={value}
            >
                {iconEl}
                {finalLoading ? spinner : <span>{finalText}</span>}
            </El>
        );
    }
}
/**
 * @deprecated use $HVUI/Button or $HVUI/CallToAction
 */
export default Button;
