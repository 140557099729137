/**
 * Takes a query string and formats it into an object.
 * @param {string} str
 * @returns object
 */
export function objectFromQuery(str) {
    if (!/\?/.test(str))
        return {};
    const stringMatch = str.match(/\?(.*)/);
    if (stringMatch === null || stringMatch === void 0 ? void 0 : stringMatch[1]) {
        return stringMatch[1].split('&').reduce((obj, n) => {
            if (typeof n === 'string') {
                n = n.split('=');
            }
            //replace + with space to work with finicky APIs
            n[1] = n[1].replace('+', ' ');
            return Object.assign(obj, {
                [n[0]]: decodeURIComponent(n[1])
            });
        }, {});
    }
    return;
}
/**
 * Takes an object and formats it as a queryString.
 * @param {object} obj
 * @returns string
 */
export function queryStringFromObject(obj) {
    let params = '';
    let builder = urlize();
    if (!!obj) {
        for (let key in obj) {
            if (obj[key] === '')
                delete obj[key];
        }
        params = !!builder(obj).length ? '?' + builder(obj) : builder(obj);
    }
    return params;
}
/**
 * Wrapper function for param string builder function
 * @returns function
 */
export function urlize() {
    const params = new Set();
    /**
     * URI encodes key values pairs in an object in preparation for use in a query string; for a full query string, see `queryStringFromObject`
     * @param {object} obj in the shape of filterValues. acceptable key value types are number, boolean, non-empty string, or nested object.
     * @returns string
     */
    return function builder(obj) {
        Object.keys(obj).forEach(key => {
            const value = obj[key];
            if (typeof value === 'object') {
                // recursively call function to extract key/values from nested objects
                builder(value);
                // ignore falsy values except 0 or intentional false
            }
            else if (value || value === 0 || value === false) {
                params.add(encodeURIComponent(key) + '=' + encodeURIComponent(value));
            }
        });
        return Array.from(params).join('&');
    };
}
