import { Alert } from '$HVUI';

/**
 * A component that checks for some common functions used in our app, and if
 * they don't exist, displays a warning that the browser is unsupported. This
 * must be added at the top level of any app that causes errors that are not
 * catchable via Error Boundaries (e.g. thrown from event handlers).
 *
 * @example
 * ```jsx
 * <Router>
 *   <UnsupportedHeader />
 *   <MyApp />
 * </Router>
 * ```
 *
 * @param {function} message A function that returns markup to display in the alert. Should ideally include a link to the faq page or support form.
 * @param {object} [style] CSS style declarations
 */
const UnsupportedHeader = props => {
    const {
        message = () => (
            <>
                <p>
                    You're using a browser that Healthvana doesn't support.
                    Please upgrade your browser or try a different
                    browser/device. For a list of supported browsers and
                    devices, please visit{' '}
                    <a href="https://healthvana.com/faq">
                        https://healthvana.com/faq
                    </a>{' '}
                    .
                </p>
                <p lang="es">
                    Estás usando un navegador que Healthvana no admite.
                    Actualice su navegador o pruebe con otro
                    navegador/dispositivo. Para obtener una lista de navegadores
                    y dispositivos compatibles, visite{' '}
                    <a href="https://healthvana.com/faq">
                        https://healthvana.com/faq
                    </a>{' '}
                    .
                </p>
            </>
        ),
        style = { marginBottom: 0 }
    } = props;

    // there are more things that can go here - please add checks for things we
    // aren't able to successfully polyfill
    const hasAbortController = typeof window.AbortController !== 'undefined';

    if (!hasAbortController) {
        return <Alert type="error" message={<>{message()}</>} style={style} />;
    }

    return <></>;
};

export default UnsupportedHeader;
