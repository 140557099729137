//Based on middleware-api from redux/real-world example
import { normalize } from 'normalizr';

import { Transmit, queryStringFromObject } from '$Utils';

/**
 * Fetches an API response and normalizes the result JSON according to schema.
 * This makes every API response have the same shape, regardless of how nested it was.
 * @deprecated no more redux and redux middleware; use useFetch
 */
const fetchAPI = (options, schema, metadata) => {
    if (options.query) {
        options.url = options.url + queryStringFromObject(options.query);
    }
    return Transmit(options).then(
        response => {
            const loadTime = new Date() - metadata.startLoad;
            if (!schema) return response;

            return Object.assign({}, normalize(response, schema));
        },
        failureResponse => {
            const loadTime = new Date() - metadata.startLoad;
            const status = failureResponse.status;
            throw failureResponse; //re-throw to allow downstream catch
        }
    );
};

/**
 * Action key that carries API call info interpreted by this Redux middleware.
 * @deprecated no more redux and redux middleware; use useFetch
 */
export const CALL_API = Symbol('Call API');

/**
 * A Redux middleware that interprets actions with CALL_API info specified. Performs the call and promises when such actions are dispatched.
 * @deprecated no more redux and redux middleware; use useFetch
 */
export const apiMiddleware = store => next => action => {
    const callAPI = action[CALL_API];
    if (typeof callAPI === 'undefined') {
        return next(action);
    }

    let { endpoint, actionPayload, query } = callAPI;

    const { schema, types, options } = callAPI;

    if (typeof endpoint === 'function') {
        endpoint = endpoint(store.getState());
    }
    if (typeof endpoint !== 'string') {
        throw new Error('Specify a string endpoint URL.');
    }
    if (!Array.isArray(types) || types.length !== 3) {
        throw new Error('Expected an array of three action types.');
    }
    if (!types.every(type => typeof type === 'string')) {
        throw new Error('Expected action types to be strings.');
    }

    if (!actionPayload) actionPayload = {};

    const actionWith = data => {
        const finalAction = Object.assign({}, action, data, actionPayload);
        delete finalAction[CALL_API];
        return finalAction;
    };

    //The `options` object is what get passed to the XHR call,
    //along with the `endpoint` property which is passed as `url`.
    //If you are POSTing data, nest the `data` object under `options`
    const transmitOptions = Object.assign(
        {},
        { url: endpoint, query: query },
        options
    );

    const [requestType, successType, failureType] = types;

    //let Redux know the request has started
    next(actionWith({ type: requestType }));

    let metadata = {
        startLoad: new Date(),
        url: endpoint,
        type: options.type && options.type.toUpperCase()
    };
    //Make the XHR call
    return fetchAPI(transmitOptions, schema, metadata).then(
        response =>
            next(
                actionWith({
                    response,
                    metadata,
                    type: successType
                })
            ),
        error => {
            return next(
                actionWith({
                    type: failureType,
                    metadata,
                    error
                })
            );
        }
    );
};
