/* eslint-disable no-useless-escape, no-control-regex */
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { IntlMessage } from '$HVUI';
import { flexibleDate, passwordStrength } from '$Utils';

/**
 * @deprecated don't use redux-form anymore
 * @param {Node|string} feedback
 * @returns {function} curried function that actually does the validation
 */
export const onlyExclusiveValuesWithMessage = feedback => (value, props) => {
    if (!value || !Array.isArray(value)) return undefined;
    if (value.length <= 1) return undefined; //only one value, it can't be invalid
    const exclusives = props?.field?.fieldExtras?.exclusiveValues;
    if (!exclusives) return undefined;
    const exclusiveMatches = exclusives.filter(e => value.includes(e));
    if (exclusiveMatches.length === 0) return undefined;
    if (exclusiveMatches.length > 1) {
        if (feedback.i18n) {
            return (
                <>
                    {feedback.multiple} {exclusiveMatches.join(', ')}
                </>
            );
        }
        return `${feedback.multiple} ${exclusiveMatches.join(', ')}`;
    }
    if (feedback.i18n) {
        return (
            <>
                {feedback.cantCombine} {`"${exclusiveMatches[0]}"`}{' '}
                {feedback.andOther}
            </>
        );
    }
    return `${feedback.cantCombine} "${exclusiveMatches[0]}" ${feedback.andOther}`;
};

/**
 * @deprecated don't use redux-form anymore
 * @returns {string}
 */
export const onlyExclusiveValues = onlyExclusiveValuesWithMessage({
    i18n: false,
    multiple: "Can't choose at the same time:",
    cantCombine: "Can't combine",
    andOther: 'and other choices'
});

/**
 * Prevents dates prior to 150 years in the past, and over 50 years in the future. Just a sanity check, good for birthdates, most dates in general.
 * @deprecated don't use redux-form anymore
 * @returns {string}
 */
export const isReasonableDateWithMessage =
    (opts = { i18n: false }) =>
    value => {
        return isDateWithinRange(
            flexibleDate('now').subtract(150, 'years'),
            opts.high,
            flexibleDate('now').add(50, 'years'),
            opts.low,
            'MM/DD/YYYY', // default formatting - always MES, even in datepolyfill
            false, //showTargetValue defaults to true - we don't want that here
            opts.i18n
        )(value);
    };

export const isReasonableDate = isReasonableDateWithMessage({
    i18n: false,
    high: 'more recent',
    low: 'a valid date'
});

// Minimum age is now configurable by company
// Overall minimum for HV is 13; in some cases
// it is set by the server

export function isValidBirthdayISO(minimum_age = 13) {
    return function (value) {
        return isDateWithinRange(
            flexibleDate('now').subtract(123, 'years'),
            'less than 123 years old',
            flexibleDate('now').subtract(parseInt(minimum_age), 'years'),
            `over ${minimum_age} years old`,
            'YYYY-MM-DD'
        )(value);
    };
}

export const isValidBirthdayWithMessage =
    (opts = { i18n: false }) =>
    (minimum_age = 13) =>
    value => {
        let lowValue;
        if (opts.i18n) {
            lowValue = (
                <>
                    {opts.low[0]}{' '}
                    <IntlMessage
                        id="all.any"
                        key="any"
                        values={{ any: minimum_age }}
                    />{' '}
                    {opts.low[1]}
                </>
            );
        } else {
            lowValue = `${opts.low[0]} ${minimum_age} ${opts.low[1]}`;
        }
        // have to add all the extra defaults to get i18n = true
        return isDateWithinRange(
            flexibleDate('now').subtract(123, 'years'),
            opts.high,
            flexibleDate('now').subtract(parseInt(minimum_age), 'years'),
            lowValue,
            'MM/DD/YYYY',
            true,
            opts.i18n
        )(value);
    };

export const isValidBirthday = isValidBirthdayWithMessage({
    low: ['over', 'years old'],
    high: 'less than 123 years old',
    i18n: false
});

export function isDatePastAndAfter2018(value) {
    return isDateWithinRange(
        flexibleDate('12/31/2018'),
        'after 2018',
        flexibleDate('now'),
        `before today`
    )(value);
}

export function isDateWithinRange(
    minDate,
    minDateName,
    maxDate,
    maxDateName,
    displayFormat = 'MM/DD/YYYY',
    showTargetDate = true,
    useI18n = false
) {
    return function (value = '') {
        //allow empty date — use notEmpty to enforce required.
        if (!value || value.length == 0) return;
        if (value.length < 10) {
            return useI18n ? (
                <IntlMessage
                    id="all.must-be"
                    defaultMessage="Must be {one}{two}"
                    values={{ one: displayFormat, two: '' }}
                />
            ) : (
                `Must be ${displayFormat}`
            );
        }
        const startDate = flexibleDate(value);
        const minimumDate = flexibleDate(minDate);
        const maximumDate = flexibleDate(maxDate);

        if (!startDate.isValid()) {
            return useI18n ? (
                <IntlMessage
                    id="all.must-be-a-valid-date"
                    defaultMessage="Must be a valid date"
                />
            ) : (
                'Must be a valid date'
            );
        }
        if (startDate.isBefore(minimumDate)) {
            const minString = minimumDate.format(displayFormat);
            if (useI18n) {
                return (
                    <IntlMessage
                        id="all.must-be"
                        defaultMessage="Must be {one}{two}"
                        values={{
                            one: minDateName,
                            two: showTargetDate ? ` (${minString})` : ''
                        }}
                    />
                );
            } else {
                return `Must be ${minDateName}${
                    showTargetDate ? ` (${minString})` : ''
                }`;
            }
        }
        if (startDate.isAfter(maximumDate)) {
            const maxString = maximumDate.format(displayFormat);
            if (useI18n) {
                return (
                    <IntlMessage
                        id="all.must-be"
                        defaultMessage="Must be {one}{two}"
                        values={{
                            one: maxDateName,
                            two: showTargetDate ? ` (${maxString})` : ''
                        }}
                    />
                );
            } else {
                return `Must be ${maxDateName}${
                    showTargetDate ? ` (${maxString})` : ''
                }`;
            }
        }
    };
}

export const notEmptyWithMessage = feedback => value => {
    if (value && Array.isArray(value)) {
        return value.length == 0 ? feedback : undefined;
    }
    return !value ? feedback : undefined;
};

export const notEmpty = notEmptyWithMessage('Required');

export const isEmailWithMessage = feedback => value => {
    const emailRegExp =
        /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
    const valid = emailRegExp.test(value);
    return valid ? undefined : feedback;
};

export const isEmail = isEmailWithMessage('Must be a valid email address');

export const matchesWithMessage =
    feedback =>
    ({ fieldName, fieldLabel }) =>
    (value, allValues) => {
        const valid = allValues[fieldName] && allValues[fieldName] === value;
        let returnValue;
        if (typeof feedback === 'string') {
            returnValue = `${feedback} ${fieldLabel}`;
        } else {
            // fragments
            // wish I didn't have to import react just for this but :sob:
            returnValue = (
                <>
                    {feedback} {fieldLabel}
                </>
            );
        }
        return valid ? undefined : returnValue;
    };

export const matches = matchesWithMessage('Must match');

export const isNumbersList = value => {
    if (Array.isArray(value)) return undefined;
    if (!value) return 'Required';
    const valid = value === value.replace(/[^\s\d\,]/g, '');
    return valid ? undefined : `List must contain only numbers and commas.`;
};

export const isNumbers = value => {
    //allow if no value. Use notEmpty in parallel to require value.
    if (!value) return undefined;
    const valid = value === value.replace(/[^\s\d]/g, '');
    return valid ? undefined : `Must contain only numbers.`;
};

export const doesNotContainNumericChars = feedback => value => {
    if (!value) return undefined;
    const valid = /\d/.test(value);
    return valid ? feedback: undefined;
};

// feedback should be an object to keep message granularity
export const isPhoneWithMessage = feedback => value => {
    if (!value) return notEmptyWithMessage(feedback.required)(value);
    const parseNumber = parsePhoneNumberFromString(value, 'US');
    return parseNumber && parseNumber.isValid() ? undefined : feedback.invalid;
};

export const isPhone = isPhoneWithMessage({
    required: 'Required',
    invalid: 'Please enter a valid phone number'
});

export const maxLengthWithMessage =
    ({ formatter, feedback }) =>
    max =>
    value => {
        if (!value) return notEmptyWithMessage(feedback.required)(value);
        return value.length <= max ? undefined : formatter(max);
    };

export const maxLength = maxLengthWithMessage({
    formatter: max => `Must be less than ${max} characters.`,
    feedback: { required: 'Required' }
});

export const minLengthWithMessage =
    ({ formatter, feedback }) =>
    min =>
    value => {
        if (!value) return notEmptyWithMessage(feedback.required)(value);
        return value.length >= min ? undefined : formatter(min);
    };

export const minLength = minLengthWithMessage({
    formatter: min => `Must be at least ${min} characters.`,
    feedback: { required: 'Required' }
});

export const maxLengthNotRequiredWithMessage = formatter => max => value => {
    if (!value) return undefined;
    return value.length <= max ? undefined : formatter(max);
};

export const maxLengthNotRequired = maxLengthNotRequiredWithMessage(
    max => `Must be less than ${max} characters.`
);

export const minLengthNotRequiredWithMessage = formatter => min => value => {
    if (!value) return undefined;
    return value.length >= min ? undefined : formatter(min);
};

export const minLengthNotRequired = minLengthNotRequiredWithMessage(
    min => `Must be at least ${min} characters.`
);

export const minValueWithMessage = formatter => min => value => {
    //allow if no value. Use notEmpty in parallel to require value.
    if (!value) return undefined;
    return value >= min ? undefined : formatter(min);
};

export const minValue = minValueWithMessage(min => `Must be at least ${min}.`);

export const maxValueWithMessage = formatter => max => value => {
    //allow if no value. Use notEmpty in parallel to require value.
    if (!value) return undefined;
    return value <= max ? undefined : formatter(max);
};

export const maxValue = maxValueWithMessage(max => `Must be less than ${max}.`);

export const isStrongPasswordWithMessage =
    feedback => fieldName => async (values, _, props) => {
        //pass isProvider or isFacilityUser true to form component to require higher strength
        let error = {};

        const minStrength = props?.isProvider || props?.isFacilityUser ? 3 : 1;
        const value = values[fieldName];
        if (!value) {
            error[fieldName] = feedback.strength;
            throw error;
        }
        if (value.length < 8) {
            error[fieldName] = feedback.length;
            throw error;
        }
        const score = await passwordStrength(value);
        if (score < minStrength) {
            let error = {};
            error[fieldName] = feedback.strength;
            throw error;
        }
    };

export const isStrongPassword = isStrongPasswordWithMessage({
    strength: 'Password must be stronger',
    length: 'Password must be at least 8 characters'
});
