import Button from './Button';

import Content from '$ContainersLegacy/Content';

/**
 * @deprecated use $HVUI/Button instead
 */
const FormButton = props => {
    const {
        clearText = 'clear',
        error: formError, //non-field errors
        id,
        invalid,
        kind,
        text,
        marginTop = 48,
        pristine,
        style,
        buttonStyle,
        submitting,
        showClear = false,
        showCancel = false,
        cancelStyle,
        clearStyle,
        cancelURL,
        cancelFunction,
        center = false,
        reset,
        element = 'input'
    } = props;

    const pristineText = props.pristineText || text || 'Submit';
    const completeText = props.completeText || text || 'Submit';
    const invalidText = props.invalidText || text || 'Submit';

    return (
        <Content marginTop={marginTop} center={center} style={style}>
            {formError && (
                <p className="text-red" style={{ marginTop: 0 }}>
                    {formError}
                </p>
            )}
            <Button
                id={id || 'button-submit'}
                element={element}
                kind={kind || 'primary'}
                disabled={invalid}
                loading={submitting}
                type="submit"
                style={buttonStyle}
                text={
                    pristine
                        ? pristineText
                        : invalid
                        ? invalidText
                        : completeText
                }
            />
            {showClear && !submitting && (
                <Button
                    id={'button-clear'}
                    element="button"
                    kind={kind || 'secondary'}
                    text={clearText}
                    style={clearStyle || { marginLeft: '24px' }}
                    onClick={reset}
                    type="button"
                />
            )}
            {showCancel && !submitting && (
                <Button
                    id={'button-cancel'}
                    element={cancelURL ? 'Link' : 'a'}
                    kind={kind || 'secondary'}
                    text="Cancel"
                    to={cancelURL}
                    onClick={cancelFunction}
                    style={cancelStyle || { marginLeft: '24px' }}
                    type="button"
                />
            )}
        </Content>
    );
};

export default FormButton;
