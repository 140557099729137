import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import * as universalLoginActions from '../../Actions/UniversalLoginActions';
import apiFactory from '../Factories/APIFactory';
import FeedbackFactory from '../Factories/FeedbackFactory';

const apiReducer = combineReducers({
    login: apiFactory([
        universalLoginActions.UNIVERSALLOGIN_REQUEST,
        universalLoginActions.UNIVERSALLOGIN_SUCCESS,
        universalLoginActions.UNIVERSALLOGIN_FAILURE
    ])
});

const rootReducer = combineReducers({
    api: apiReducer,
    form: formReducer,
    feedback: FeedbackFactory({ onSubmit: true })
});

export default rootReducer;
