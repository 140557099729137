import { CALL_API } from '$ReduxLegacy/Middleware/API';
import { organizationSchema } from '$Schemas/SharedSchemas';
import { queryStringFromObject } from '$Utils';

// =================== ME
// Gets details about the currently logged-in user.

export const ME_REQUEST = 'ME_REQUEST';
export const ME_SUCCESS = 'ME_SUCCESS';
export const ME_FAILURE = 'ME_FAILURE';

const getMe = () => ({
    [CALL_API]: {
        types: [ME_REQUEST, ME_SUCCESS, ME_FAILURE],
        endpoint: '/api/v2/userprofiles/me/',
        schema: false,
        options: {
            type: 'GET'
        }
    }
});

export const loadMe = () => (dispatch, getState) => {
    if (!!getState().me.id) {
        return Promise.resolve({ response: getState().me });
    }
    return dispatch(getMe());
};

//View: NavigationDrawer (Patient Only)
export const NAVIGATIONDRAWER_TOGGLE_DRAWER = 'NAVIGATIONDRAWER_TOGGLE_DRAWER';

export const navigationdrawer_toggleDrawer = function () {
    return { type: NAVIGATIONDRAWER_TOGGLE_DRAWER };
};

// =================== ORGS
// Gets details about organizations a patient has visited, or a provider's current org

export const ORGS_REQUEST = 'ORGS_REQUEST';
export const ORGS_SUCCESS = 'ORGS_SUCCESS';
export const ORGS_FAILURE = 'ORGS_FAILURE';

const getOrgs = options => ({
    [CALL_API]: {
        types: [ORGS_REQUEST, ORGS_SUCCESS, ORGS_FAILURE],
        endpoint: '/api/v2/organizations/' + queryStringFromObject(options),
        schema: [organizationSchema],
        options: {
            type: 'GET'
        }
    }
});

export const loadOrgs = options => (dispatch, getState) => {
    if (!!getState().api?.organizations?.lastFetch) {
        return Promise.resolve();
    }
    return dispatch(getOrgs(options));
};

export const SET_FEEDBACK = 'SET_FEEDBACK';

export const setFeedback = ({ message, kind }) => {
    return { type: SET_FEEDBACK, message: message, kind: kind };
};

export const CLEAR_FEEDBACK = 'CLEAR_FEEDBACK';

export const clearFeedback = () => {
    return { type: CLEAR_FEEDBACK };
};

//Pagination
export const CHANGE_PAGINATION = 'CHANGE_PAGINATION';

export const changePagination = ({ amount, key }) => {
    return { type: CHANGE_PAGINATION, key: key, amount: amount };
};

export const RESET_PAGINATION = 'RESET_PAGINATION';

export const resetPagination = key => {
    return { type: RESET_PAGINATION, key: key };
};
