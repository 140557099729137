import { capitalize } from 'lodash';

import { HVUser } from './constants';

/**
 * Takes an array of objects and returns an object with items keyed by keyName
 *
 *
 * This is a convenience function to transform data from an
 * array to an object, often an ID.
 *
 * NOTE: The value for keyName must be unique for all items
 * in the array.
 *
 * @example
 * ```js
 * arrayToObjectByKey([{name: "sam"}, {name: "ian"}], "name")
 * // returns {sam: {name: 'sam'}, ian: {name: 'ian'}}
 * ```
 * @param {array} array The array to pull objects from
 * @param {string} keyName The key to use for each item in the new object
 * @return {object} The completed array, with each item referenced by keyName
 */
export function arrayToObjectByKey(array, keyName) {
    if (!array) return null;
    if (!Array.isArray(array))
        throw new Error('Array argument is not an array');

    return array.reduce((result, item, index) => {
        if (!item[keyName])
            throw new Error(
                `Key ${keyName} not found in ${JSON.stringify(item)}`
            );
        if (result[item[keyName]])
            throw new Error(`Duplicate Key: ${item[keyName]}`);
        result[item[keyName]] = array[index];
        return result;
    }, {});
}

/**
 * Takes a user profile and returns a string that combines the
 * profile's first, last, and preferred names.
 *
 * @example
 * ```js
 * fullNameFromProfile({
 *     first_name: "Kate", last_name: "Bishop", preferred_name: "Hawkeye", ...
 * })
 * //=> "Kate Bishop (Hawkeye)"
 * ```
 * @param {object} userProfile The user profile to pull values from
 * @returns {string} The formatted name with Legal First Name,
 * then Last Name, then Preferred Name in parens if it exists
 */
export function fullNameFromProfile(userProfile) {
    if (!userProfile) return '';
    const { first_name = '', last_name = '', preferred_name } = userProfile;
    if (preferred_name && preferred_name !== first_name) {
        return `${first_name} ${last_name} (${preferred_name})`;
    }
    return `${first_name} ${last_name}`;
}

/**
 * Formats a data property as a readable label.
 * @example
 * ```js
 * makeLabel(something_to_display)
 * //Something To Display
 * ```
 * @param {string} str The data key string to format.
 * @param {string} [splitChar=_] The glyph to split on; defaults to underscore(_)
 * @returns string The formatted readable String
 */

export const makeLabel = (str, splitChar = '_') =>
    str
        .split(splitChar)
        .map(w => capitalize(w))
        .join(' ')
        .replace(/Id/g, 'ID');

/**
 *
 * @param {array} providerData
 * @param {object} orgData
 * @returns {array}
 */
export const formatAssigneeLists = (providerData = [], orgData) => {
    if (!orgData)
        return {
            assignees: [],
            assigneesWithOrganizationOptions: []
        };
    const reassignmentOrgChoices = Object.values(orgData)
        .sort((a, b) => {
            return a.short_name
                .toLowerCase()
                .localeCompare(b.short_name.toLowerCase());
        })
        .map(org => [`org_${org.id}`, org.short_name]);

    const newAssignees = providerData
        .sort((a, b) => {
            return a.last_name
                .toLowerCase()
                .localeCompare(b.last_name.toLowerCase());
        })
        .map(user => [user.user, `${user.last_name}, ${user.first_name}`]);
    // Assignees includes HV staff generic entry to cover all CS folks
    const assignees = [['', 'All']]
        .concat(newAssignees)
        .concat([[HVUser.id, 'Healthvana Staff']]);
    const assigneesWithOrganizationOptions = assignees
        .concat([[null, ' ']])
        .concat(reassignmentOrgChoices);

    return {
        assignees,
        assigneesWithOrganizationOptions
    };
};
/**
 *
 * @param {array} providerData
 * @returns {array}
 */
export const formatCreatorList = (providerData = []) => {
    const newCreators = providerData
        .sort((a, b) => {
            return a.last_name
                .toLowerCase()
                .localeCompare(b.last_name.toLowerCase());
        })
        .map(user => [user.user, `${user.last_name}, ${user.first_name}`]);
    // creators can include our System flags but not staff
    const creators = [['', 'All']]
        .concat(newCreators)
        .concat([[HVUser.id, 'Healthvana System']]);
    return {
        creators
    };
};

/**
 *
 * @param {number} activeFlag
 * @param {array} providerList
 * @param {array} providerAndOrgList
 * @returns {array}
 */
export const displayAssigneesForFlag = (
    activeFlag,
    providerList,
    providerAndOrgList
) => {
    return activeFlag && activeFlag.category == 'Results Survey'
        ? providerList
        : providerAndOrgList;
};
