import styles from './LoggedOutHeader.module.less';

import { Logo } from '$HVUI';

const Header = () => (
    <header className={styles['header']}>
        <a
            className={styles['logo']}
            href="https://healthvana.com"
            aria-label="Visit the Healthvana homepage"
        >
            <Logo reversed />
        </a>
    </header>
);

export default Header;
