import { StatusBar, Style } from '@capacitor/status-bar';
import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';

import CookieError from './CookieError/CookieError';
import styles from './UniversalLogin.module.less';
import UniversalLoginForm from './UniversalLoginForm';

import SingleColumn from '$ContainersLegacy/SingleColumn';
import { Alert, LoggedOutHeader } from '$HVUI';
import { setFeedback } from '$ReduxLegacy/Actions/SharedActions';
import { handleUniversalLogin } from '$ReduxLegacy/Actions/UniversalLoginActions';
import { AuthenticatedStorage } from '$Shared/Capacitor';

export class UniversalLogin extends React.Component {
    state = { error: null };
    componentDidMount() {
        window.scrollTo(0, 0);
        const { setFeedback } = this.props;
        // persist next redirect from query string
        const parsed = queryString.parse(window.location.search);
        if (parsed.next) {
            this.setState({
                next: parsed.next
            });
        }
        if (parsed.email_confirmed === 'true') {
            setFeedback({
                kind: 'success',
                message: 'Your e-mail address has been confirmed.'
            });
        }
        if (parsed.error) {
            setFeedback({ kind: 'error', message: parsed.error });
        }
        if (parsed.success) {
            setFeedback({ kind: 'success', message: parsed.success });
        }

        const setStatusBarStyleDark = async () => {
            const hasBridge = window.Capacitor.isPluginAvailable('StatusBar');
            if (!hasBridge) return;
            await StatusBar.setStyle({ style: Style.Dark });
        };
        setStatusBarStyleDark();

        this.checkForSavedCredentials();
    }
    checkForSavedCredentials = async () => {
        const hasBridge = window.Capacitor.isPluginAvailable(
            'AuthenticatedStorage'
        );
        if (!hasBridge) return;

        const result = await AuthenticatedStorage.loadCredentials();
        if (!result || !result.username || !result.password) return;
        if (result.username === ' ') return;
        //we have valid username and password, use them.
        this.handleSubmit(result, true);
    };
    saveCredentials = async (username, password, fromStorage) => {
        //Three cases:
        //  1. If not iOS or user/pw are from storage, move on immediately
        //  - Ask if they want to store user/pw:
        //  2. Yes: Store credentials then move on
        //  3. No: Just move on.
        const hasBridge = window.Capacitor.isPluginAvailable(
            'AuthenticatedStorage'
        );
        if (!hasBridge || fromStorage === true) return;

        await AuthenticatedStorage.storeCredentials({
            username,
            password
        });
    };
    clearErrors = () => {
        this.setState({
            error: null
        });
    };
    handleSubmit = (values, fromStorage) => {
        const { username, password } = values;
        return this.props
            .handleUniversalLogin(username, password, this.state.next)
            .then(async data => {
                if (data.type.includes('SUCCESS')) {
                    await this.saveCredentials(username, password, fromStorage);
                    window.location.href = data.response.next;
                } else {
                    if (data.error) {
                        //Temporarily update so Se tests pass.
                        let message = 'An error occured.';
                        if (data.error.status == 400) {
                            message =
                                'Please enter a correct username and password. Note that both fields may be case-sensitive.';
                            try {
                                let response = JSON.parse(data.error.response);
                                if (
                                    typeof response.message_slug !==
                                        'undefined' &&
                                    response.message_slug ==
                                        'cookie-test-failed'
                                ) {
                                    message =
                                        'You must have cookies turned on to use Healthvana. Check your settings and refresh the page before logging in. If you need assistance, use our contact form at https://healthvana.com/contact/.';
                                }
                            } catch (e) {
                                return;
                            }
                        }
                        if (data.error.status == 429) {
                            message =
                                'Too many login attempts. Please try again later.';
                        }
                        this.props.setFeedback({
                            kind: 'error',
                            message: message
                        });
                    }
                }
                if (data.error && data.error.status == 401) {
                    let response = JSON.parse(data.error.response);
                    window.location.href = response.next;
                    return;
                }
            });
    };
    render() {
        const { feedback } = this.props;
        const initialValues = this.props.forceValues;

        return (
            <main>
                <LoggedOutHeader />
                {feedback.kind && (
                    <Alert
                        style={{
                            margin: '1rem auto',
                            width: 'max-content'
                        }}
                        type={feedback.kind}
                        message={feedback.message}
                        showIcon
                    />
                )}
                <SingleColumn marginTop={48} marginBottom={72}>
                    <CookieError />
                    <h1
                        id="header-signin"
                        style={{
                            textAlign: 'center',
                            marginBottom: '32px'
                        }}
                    >
                        Sign in to your account
                    </h1>
                    <UniversalLoginForm
                        initialValues={initialValues}
                        onSubmit={this.handleSubmit}
                    />
                    <div style={{ textAlign: 'center', marginTop: '32px' }}>
                        <a id="forgot-password" href="/forgot-password/">
                            Forgot Password?
                        </a>
                    </div>
                    <div
                        style={{
                            margin: '24px',
                            textAlign: 'center',
                            fontSize: '22px'
                        }}
                    >
                        <span style={{ color: 'var(--gray200)' }}>
                            Need Help?{' '}
                        </span>
                        <a
                            id="contact-us"
                            href="https://healthvana.com/contact/"
                        >
                            Contact Us.
                        </a>
                    </div>
                </SingleColumn>
                <div className={styles['disclaimer']}>
                    <p className="text-small">
                        All content of this system and its associated
                        sub-systems are PROPRIETARY INFORMATION and remain the
                        sole and exclusive property of this company or its
                        customers. This system may be accessed and used by
                        authorized personnel only. Authorized users may only
                        perform authorized activities and may not exceed the
                        limits of such authorization. Disclosure of information
                        found in this system for any unauthorized use is{' '}
                        <strong>STRICTLY PROHIBITED</strong>. All activities on
                        this system are subject to monitoring. Intentional
                        misuse of this system can result in disciplinary action
                        or criminal prosecution.
                    </p>
                </div>
            </main>
        );
    }
}

export const mapStateToProps = (state, ownProps) => {
    return Object.assign({}, ownProps, state);
};

export default connect(mapStateToProps, { setFeedback, handleUniversalLogin })(
    UniversalLogin
);
