import cookie from 'cookie-cutter';
import { merge } from 'lodash'; // options helper
import reqwest from 'reqwest';
import 'whatwg-fetch'; // polyfill always

import { HVSentry } from '$Utils/sentry-utils';

/*
FETCHER.JS
--------
Convenience methods for XHR using the native window.fetch.

(Is there seriously not a standard wrapper lib for this that has stars and doesn't suck? Boo.)
*/

//Helper methods - exported for tests only.

// window.fetch has much nicer formatting for errors to begin with, so it seems
// *knock on wood* like we won't have to do a lot of manual try / catching
// ourselves like we did with the old system.

// Mostly we just want to capture a little extra metadata and send it back
// to the component along with the error.

export const _rejectWithMeta = (error, metadata) => {
    if (error == null) {
        HVSentry.sendMessageToDjangoSentry(metadata);
    }
    return Promise.reject({ error, metadata });
};

// JSON response handling
export function _handleJSONResponse(response, metadata = {}) {
    return response
        .json()
        .then(json => {
            json.__metadata = metadata;
            return json;
        })
        .catch(error => {
            return _rejectWithMeta(error, { ...metadata, parseError: true });
        });
}

//Text handling
export function _handleTextResponse(response, metadata = {}) {
    return response
        .text()
        .then(text => {
            return text;
        })
        .catch(error => {
            return _rejectWithMeta(error, {
                ...metadata,
                isHTML: true,
                parseError: true
            });
        });
}

// Default fetcher.
// It's wide open access to fetch, with
// unpacking of JSON & basic promise rejection
// handled for you.

// Used as a base for all subsequent functions.
export const fetcher = (url, options = {}) => {
    // Uses global window.fetch by default.
    // Polyfills if not available.
    let metadata = {
        method: (options.method && options.method.toUpperCase()) || 'GET',
        startLoad: new Date(),
        url
    };
    return (
        fetch(url, options)
            .then(response => {
                let contentType = response.headers.get('content-type');
                metadata.status = response.status;
                metadata.loadTime = new Date() - metadata.startLoad;
                if (contentType && contentType.includes('application/json')) {
                    return _handleJSONResponse(response, metadata);
                }
                if (contentType && contentType.includes('text/html')) {
                    return _handleTextResponse(response, metadata);
                }

                // Sometimes DELETE returns nothing
                if (options.method && options.method == 'DELETE')
                    return response;

                // For anything other than HTML and text,
                // on anything other than DELETES, assume failure for now
                return Promise.reject(response);
            })
            // If sent as error directly from server, catch and
            // re-reject after Sentry log, this time with formatting
            .catch(error => {
                metadata.loadTime = new Date() - metadata.startLoad;
                return _rejectWithMeta(error, metadata);
            })
    );
};

// Named methods. Can still pass options,
// but sets some sensible defaults for easy reuse.

let methodNames = ['post', 'put', 'patch', 'options', 'get', 'delete'];

const methods = methodNames.reduce((obj, methodString) => {
    let fetchMethod = (baseURL, options = {}) => {
        // add '/' to url arg when calling the function for absolute path, otherwise will default to relative path
        let url = baseURL;
        const { data } = options;

        //JSON & authed by default
        let defaults = {
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': cookie.get('csrftoken')
            },
            method: methodString.toUpperCase()
        };

        const settings = merge({}, defaults, options);

        if (data) {
            //get needs params to be included in URL
            if (methodString === 'get') {
                let encode = encodeURIComponent;
                let query = Object.keys(data)
                    .map(key => `${encode(key)}=${encode(data[key])}`)
                    .join('&');
                url = `${url}?${query}`;
            } else {
                // If data present
                if (settings.headers['Content-Type'].includes('json')) {
                    // if JSON, stringify it
                    settings.body = JSON.stringify(data);
                } else {
                    // if something else, leave as is
                    settings.body = data;
                }
            }
        }
        return fetcher(url, settings);
    };
    obj[methodString] = fetchMethod;
    return obj;
}, {});

export const del = methods['delete'];
// delete is a reserved word
export const get = methods.get;
export const options = methods.options;
export const patch = methods.patch;
export const post = methods.post;
export const put = methods.put;

// OLD STUFF

// Have to manually process JS object into a uriEncoded string,
// because otherwise keys with a value type of Array will not
// be posted properly.
/**
 * @deprecated Use fetch-utils or useFetch
 * @param {object} settings
 * @returns object
 */
function prepSettings(settings) {
    let othersettings = [];

    settings.method = settings.type;
    settings.type = settings.dataType;

    if (settings.contentType.indexOf('urlencoded') !== -1) {
        for (let key in settings.data) {
            if (Array.isArray(settings.data[key])) {
                settings.data[key].forEach(item => {
                    let obj = {};
                    obj[key] = item;
                    othersettings.push(obj);
                });

                delete settings.data[key];
            }
        }

        if (!othersettings.length) return settings;
        let stringData = '';
        othersettings.forEach(item => {
            stringData += '&' + reqwest.toQueryString(item);
        });
        stringData += '&' + reqwest.toQueryString(settings.data);
        stringData = stringData.substring(1);
        settings.data = stringData;
    }

    settings.processData = false;

    return settings;
}
/**
 * @deprecated Use fetcher methods or useFetch
 * @param {object} settings
 * @returns object
 */
export function Transmit(options) {
    const csrf = cookie.get('csrftoken');
    let defaults = {
        type: 'POST',
        dataType: 'json',
        headers: {
            'X-CSRFToken': csrf
        },
        contentType: 'application/x-www-form-urlencoded'
    };
    let settings = prepSettings(merge(defaults, options));

    return new Promise(function (resolve, reject) {
        return reqwest(settings)
            .fail(data => {
                if (data.status === 200) {
                    resolve(data)
                }
                reject(data)
            })
            .then(data => resolve(data));
    });
}

/**
 * Stores an event to our AWS data lake
 *
 *
 * This is a convenience function to store arbitrary event
 * payloads.
 *
 * For example, to be able to track expiring events
 * back to the exact token:
 * @example
 * ```js
 * storeEvent("contact_trace_created", { token, callToActionVersion: "c" })
 * ```
 *
 * @param {string} label The key to store the payload under
 * @param {object} payload JSON object to be stored
 * @return {boolean} Whether or not the request was successful
 */
export async function storeEvent(label, payload) {
    const isProd = process.env.NODE_ENV === 'production';
    try {
        //if not production, add dev tag to label
        await fetch(
            'https://22f642o0gl.execute-api.us-west-2.amazonaws.com/v1',
            {
                method: 'POST',
                mode: 'no-cors',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(
                    Object.assign({}, payload, {
                        label: isProd ? label : `${label}-dev`
                    })
                )
            }
        );
    } catch (error) {
        HVSentry.sendExceptionToReactSentry(error, {
            tags: {
                'event-collector-label': label
            }
        });
        console.error(error);
    }
}

/**
 * Finds the CSRF token embedded in all of our django templates, and returns
 * its value. If document or csrfmiddlewaretoken don't exist, returns null
 *
 * This is required for any non-XHR form submission that we make.
 *
 * @return {string} The token value (or null), usually a long hex string
 */
export function csrfToken() {
    return document?.getElementsByName('csrfmiddlewaretoken')?.[0]?.value;
}

/**
 * @deprecated this is just bad code, no replacement suggested
 * @param {object} endpoint
 * @returns boolean
 */
export function isLoading(endpoint) {
    if (!endpoint) return false;
    if (!endpoint.lastFetch || !!endpoint.fetching) return true;
    return false;
}

/**
 *
 * @param {string} filename
 * @param {string} data
 * @param {string} [type]
 */
export const download = (filename, data, type = 'text') => {
    let element = document.createElement('a');
    let content;
    if (type === 'text') {
        content = `data:text/plain;charset=utf-8,${encodeURIComponent(data)}`;
    } else {
        content = data;
    }
    element.setAttribute('href', content);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();
    document.body.removeChild(element);
};
