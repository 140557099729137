import { schema } from 'normalizr';

import { fullNameFromProfile } from '$Utils';
/**
 * @deprecated Don't use normalizer or redux
 */
export const organizationSchema = new schema.Entity(
    'organizations',
    {},
    {
        processStrategy: function (value, parent, key) {
            let newValue = Object.assign({}, value);
            if (!newValue.short_name) newValue.short_name = newValue.name;
            return newValue;
        }
    }
);
/**
 * @deprecated Don't use normalizer or redux
 */
export const processNames = function (value) {
    const { first_name = '', last_name = '', preferred_name } = value;
    let newValue = Object.assign({}, value);
    newValue.preferred_name = preferred_name || first_name;
    newValue.full_name = fullNameFromProfile(value);
    const firstInitial = newValue.preferred_name[0] || ' ';
    const lastInitial = last_name[0] || ' ';
    newValue.initials = `${firstInitial}${lastInitial}`.toUpperCase();
    return newValue;
};
/**
 * @deprecated Don't use normalizer or redux
 */
export const userSchema = new schema.Entity(
    'users',
    {},
    {
        processStrategy: processNames
    }
);

export const profileSchema = new schema.Entity(
    'profiles',
    {},
    {
        processStrategy: processNames
    }
);

export const companyUserSchema = new schema.Entity(
    'companyUsers',
    {
        selected_organization: organizationSchema,
        mapped_organizations: [organizationSchema]
    },
    { processStrategy: processNames }
);
