const Index = ({
    center,
    children,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    style
}) => {
    const margins = { marginLeft, marginRight, marginTop, marginBottom };
    const centerStyle = center ? { textAlign: 'center' } : {};
    const finalStyle = Object.assign(margins, centerStyle, style);
    return <div style={finalStyle}>{children}</div>;
};

// Content is short for Content Container, for non-block elements
// that you might wish to position and or group together a certain way.
// Spacing and centering can be applied to text elements directly.

export default Index;
