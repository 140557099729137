import { FormattedMessage } from 'react-intl';

/**
 * Wrapper component for FormattedMessage: https://formatjs.io/docs/react-intl/components#formattedmessage
 * This component should only be used for text formatting. If you need other
 * functionality, create a new component.
 * @param {object} values object describing substitutions and formatting.
 * @example
 * ```
 * <IntlMessage id="test" values={{ mySubstitutionKey: mySubstitutionValue }} />
 * ```
 */

const IntlMessage = props => {
    const defaultOps = {
        bold: chunks => <strong>{chunks}</strong>,
        italic: chunks => <em>{chunks}</em>,
        p: chunks => <p>{chunks}</p>
    };
    const { values } = props;
    const allValues = Object.assign({}, defaultOps, values);
    return (
        <span>
            <FormattedMessage {...props} values={allValues} />
        </span>
    );
};

export default IntlMessage;
