import React from 'react';
import { Field, reduxForm } from 'redux-form';

import TextFieldCSS from '$Fields/TextField/TextField-css';
import FormButton from '$Molecules/Buttons/FormButton';
import { notEmpty } from '$Validation/Validator';

export class UniversalLoginForm extends React.Component {
    render() {
        const { handleSubmit, invalid, submitting } = this.props;

        return (
            <form
                onSubmit={handleSubmit}
                data-complete={!invalid && !submitting}
            >
                <Field
                    name="username"
                    component={TextFieldCSS}
                    data={{ label: 'email or username' }}
                    validate={[notEmpty]}
                />
                <Field
                    name="password"
                    component={TextFieldCSS}
                    data={{ label: 'password', obscured: true }}
                    validate={[notEmpty]}
                />
                <FormButton
                    pristineText="Sign in"
                    invalidText="Sign in"
                    completeText="Sign in"
                    center={true}
                    {...this.props}
                />
            </form>
        );
    }
}

export default reduxForm({
    form: 'UniversalLoginForm'
})(UniversalLoginForm);
