import { composeWithDevTools } from '@redux-devtools/extension';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

const configureStore = (rootReducer, options, preloadedState) => {
    let baseMiddleware = [];
    let baseOptions = [];

    const ourCompose =
        process.env.NODE_ENV == 'production' ? compose : composeWithDevTools;

    if (options) {
        if (options.thunk) {
            baseMiddleware.push(thunk);
        }
        if (options.middleware) {
            baseMiddleware = baseMiddleware.concat(options.middleware);
        }
    }

    baseOptions = baseOptions.concat([
        applyMiddleware.apply(this, baseMiddleware)
    ]);

    return ourCompose.apply(this, baseOptions)(createStore)(
        rootReducer,
        preloadedState
    );
};

export default configureStore;
