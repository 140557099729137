import FieldWrapper from '../Field-wrapper';
import styles from '../Fields.module.less';
/**
 *
 * @deprecated all new forms should use HVUI/FormikForm and HVUI/FormControl
 */
const TextFieldInput = ({
    autoComplete, //default is on, use 'off' for false
    autoCorrect, //default is on, use 'off' for false
    autoCapitalize, //default is on, use 'off' for false
    disabled,
    id,
    inputStyle,
    inputClassName,
    inputMode,
    max,
    maxLength,
    min,
    name,
    obscured,
    onChange,
    onFocus,
    onBlur,
    pattern,
    placeholder,
    required,
    spellCheck,
    type = 'text',
    value
}) => (
    <input
        autoCapitalize={autoCapitalize}
        autoComplete={autoComplete}
        autoCorrect={autoCorrect}
        className={styles[inputClassName]}
        disabled={disabled}
        id={id || name}
        inputMode={inputMode}
        max={max}
        maxLength={maxLength}
        min={min}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        pattern={pattern}
        placeholder={placeholder}
        required={required}
        spellCheck={spellCheck}
        style={inputStyle}
        type={obscured ? 'password' : type}
        value={value || ''} //? check
    />
);
/**
 *
 * @deprecated all new forms should use HVUI/FormikForm and HVUI/FormControl
 */
export default FieldWrapper(TextFieldInput, { reduxForm: true });

/**
 *
 * @deprecated all new forms should use HVUI/FormikForm and HVUI/FormControl
 */
export const TextField = FieldWrapper(TextFieldInput);
