import * as sharedActions from '../../Actions/SharedActions';

import { formatErrorObject } from '$Utils';

const initialState = {
    message: '',
    kind: '',
    url: ''
};

const FeedbackFactory = (options = { authSignout: true }) => {
    const onSubmit = !!options.onSubmit;

    //Setting determines whether feedback is cleared on submit or on change
    const reduxAction = onSubmit
        ? '@@redux-form/START_SUBMIT'
        : '@@redux-form/CHANGE';

    return (
        state = Object.assign({}, initialState),
        { type, message, kind, error }
    ) => {
        //prioritize manual setting and clearing of feedback
        if (type === sharedActions.SET_FEEDBACK) {
            return Object.assign({}, state, {
                message,
                kind
            });
        }

        if (type === sharedActions.CLEAR_FEEDBACK || type === reduxAction) {
            return Object.assign({}, initialState);
        }

        //Listen for actions created by failed API calls
        if (type.includes('FAILURE')) {
            const finalError = formatErrorObject({ error });

            // Anything that is not a top level error should be handled
            // at the Field level only
            if (!finalError._error) {
                return state;
            }
            // Finally, extract anything that's a non-field error
            let finalMessage = finalError._error;
            // If there's more than one, only show the first
            if (Array.isArray(finalMessage)) {
                finalMessage = finalMessage[0];
            }

            // 500s return a more detailed response with an email link
            // for troubleshooting, handled by the component itself
            if (error.status == 500) {
                return {
                    message: '',
                    kind: 'failure',
                    url: error.responseURL
                };
            }

            return Object.assign({}, state, {
                message: finalMessage,
                kind: 'error'
            });
        }

        //default
        return state;
    };
};

export default FeedbackFactory;
