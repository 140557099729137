import colors from '../colors.module.less';
import './index.module.less';

/**
 * An SVG version of the Healthvana Logo Mark that can be customized and dropped into a React app.
 * @date 2021-03-23
 * @param {string} [ariaLabel] Aria labeling.
 * @param {string} [className] A LESS classname
 * @param {string} [color] Color of the logo.
 * @param {string} [role='presenation'] Accessible role.
 * @param {string|number} [size] How big the logo should be. A number will be interpreted as pixels. For other units, use a string to specify the unit type. Not including a size will cause the logo to take up the entirety of its container.
 * @param  {boolean} [standalone=true] Whether the heart is being used as part of the main logo. If using it as an export, this should always be true, so it is wrapped in the SVG tag.
 * @param {object} [style] Additional js format styles.
 */
export const LogoMark = props => {
    const {
        ariaLabel,
        className,
        color,
        role = 'presentation',
        size,
        style
    } = props;

    return (
        <svg
            id="logo"
            aria-label={role !== 'presentation' && ariaLabel}
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            className={className}
            height={size}
            width={size}
            role={role}
            viewBox={'0 0 44 36.3'}
            style={style}
        >
            <path
                id="heart"
                fill={colors[color] || color || 'inherit'}
                d="M34.9,9.2c1.1,1.1,1.1,2.9,0,4.1L16.3,31.8l-13-13c-2.8-2.7-3.9-6.8-3-10.6s4-6.8,7.8-7.8s7.8,0.1,10.6,3l1.6,1.8l-4.1,4.1l-1.7-1.7c-2-1.9-5.2-1.9-7.2,0.1s-2,5.2-0.1,7.2l8.9,8.9L30.9,9.2C32,8,33.8,8,34.9,9.2z
M40.8,3.4c4.2,4.2,4.2,11.1,0,15.4L24.2,35.4c-1.1,1.1-2.9,1.1-4.1,0l-2-2l18.5-18.5c2-2,2.1-5.3,0.1-7.3c-1-1-2.4-1.6-3.8-1.5
c-1.3,0.1-2.6,0.6-3.5,1.6L16.5,20.4l-7.3-7.3c-1.1-1.1-1.1-2.9,0-4s2.9-1.1,4,0l3.2,3.2l8.9-8.9C29.7-0.9,36.6-0.9,40.8,3.4z"
            />
        </svg>
    );
};

/**
 * An SVG version of the full Healthvana Logo that can be customized and dropped into a React app.
 * @date 2021-03-23
 * @param {string} [ariaLabel] Aria labeling.
 * @param {string} [className] A LESS classname
 * @param {string} [color='ocean500'] Color of the logo. Can be string of  one of a subset of colors exported in HVUI.colors.less (recommended - see Storybook or code below for list), or an actual hex value (not recommended).
 * @param {boolean} [markOnly=false] DEPRECATED. Use the export LogoMark for additional flexibility.
 * @param {boolean} [mono=false] Whether or not the entire logo should be the same color.
 * @param {string} [role='presentation'] Accessible role.
 * @param {string|number} [size] How big the logo should be. A number will be interpreted as pixels. For other units, use a string to specify the unit type. Not including a size will cause the logo to take up the entirety of its container.
 * @param {object} [style] Additional js format styles.
 */
const Logo = ({
    ariaLabel,
    className,
    color = 'ocean500',
    markOnly = false, //DEPRECATED, use LogoMark
    mono = false,
    role = 'presentation',
    reversed = false,
    size,
    style
}) => {
    const colorFinal = [
        'ocean500',
        'gray300',
        'white',
        'gray200',
        'gray400'
    ].includes(color)
        ? colors[color]
        : color;
    const viewBox = `0 0 ${markOnly ? 44 : 224} 36.3`;
    return (
        <svg
            style={style}
            id="logo"
            aria-label={role !== 'presentation' ? ariaLabel : undefined}
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox={viewBox}
            className={className}
            height={size}
            width={size}
            role={role}
        >
            <title>Healthvana logo</title>
            {!markOnly && (
                <path
                    id="logotype"
                    fill={
                        mono
                            ? colorFinal
                            : reversed
                            ? colors['white']
                            : colors['gray200']
                    }
                    d="M75.9,16.1V6.2h2v21.3h-2v-9.6H62v9.6h-2V6.2h2v9.9H75.9z M89.5,11.4c4.5,0,7.6,3.4,7.5,8.4v0.8
    H83.6c0.3,3.5,2.9,5.9,6.2,5.9c2.1,0,4.1-0.9,5.5-2.4l0.1-0.1l1.4,1.1l-0.2,0.1c-1.7,2-4.2,3.1-6.9,3c-4.6,0-8.1-3.6-8.1-8.4v-0.1
    C81.7,15.1,85.1,11.4,89.5,11.4z M89.5,13.1c-3,0-5.5,2.4-5.8,5.8h11.5C94.9,16.2,93.3,13.1,89.5,13.1z M107.4,11.4
    c4.4,0,7,2.3,6.9,6.3v10h-1.9v-2.3c-1.5,1.7-3.7,2.7-6.1,2.7c-4.2,0-6.4-2.6-6.4-5.1v-0.1c0-3.3,2.8-5.3,7.3-5.3
    c1.8,0,3.5,0.2,5.2,0.7v-0.5c0-3-1.8-4.6-5.2-4.6c-1.8,0-3.5,0.4-5,1.2l-0.2,0.1l-0.7-1.6l0.2-0.1C103.4,11.9,105.4,11.5,107.4,11.4
    z M112.5,20c-1.7-0.4-3.5-0.7-5.3-0.7c-3.3,0-5.2,1.3-5.2,3.5v0.1c0,2.3,2.3,3.4,4.6,3.4c3.4,0,6-2,6-4.7L112.5,20L112.5,20z
     M118.9,6.2h1.9v21.3h-1.9V6.2z M129.2,6.2v5.3h5.7v1.7h-5.7v10c0,1.9,1.1,2.9,3.1,2.9c0.8,0,1.6-0.2,2.3-0.5l0.2-0.1v1.7h-0.1
    c-0.8,0.4-1.8,0.6-2.7,0.6c-3,0-4.7-1.7-4.7-4.5V13.2H125v-1.7h2.4V6.2H129.2z M146.8,11.2c4,0,6.7,2.6,6.7,6.6v9.8h-1.9v-9.5
    c0-3.2-1.9-5.2-5-5.2c-1.5,0-2.9,0.6-4,1.6s-1.6,2.4-1.5,3.9v9.2h-1.9V6.2h1.9V14C142.4,12.2,144.5,11.1,146.8,11.2z M163.7,25.2
    l6.1-13.8h2.1l-7.4,16.1h-1.6v-0.1l-7.4-16.1h2.2L163.7,25.2L163.7,25.2z M180.5,11.4c4.4,0,7,2.3,6.9,6.3v10h-1.9v-2.3
    c-1.5,1.7-3.7,2.7-6.1,2.7c-4.2,0-6.4-2.6-6.4-5.1v-0.1c0-3.3,2.8-5.3,7.3-5.3c1.8,0,3.5,0.2,5.2,0.7v-0.5c0-3-1.8-4.6-5.2-4.6
    c-1.8,0-3.5,0.4-5,1.2l-0.2,0.1l-0.7-1.6l0.1-0.1C176.5,11.9,178.5,11.5,180.5,11.4L180.5,11.4z M185.6,20c-1.7-0.4-3.5-0.7-5.3-0.7
    c-3.3,0-5.2,1.3-5.2,3.5v0.1c0,2.3,2.3,3.4,4.6,3.4c3.4,0,6-2,6-4.7V20L185.6,20z M199.6,11.4c4.1,0,6.7,2.6,6.7,6.6v9.8h-1.9v-9.5
    c0-3.2-1.9-5.2-5-5.2c-1.5,0-2.9,0.6-4,1.6c-1,1-1.6,2.4-1.5,3.9v9.2H192v-16h1.9v2.5C195.2,12.4,197.3,11.3,199.6,11.4L199.6,11.4z
     M217.1,11.4c4.4,0,7,2.3,6.9,6.3v10h-1.9v-2.3c-1.5,1.7-3.7,2.7-6.1,2.7c-4.2,0-6.4-2.6-6.4-5.1v-0.1c0-3.3,2.8-5.3,7.3-5.3
    c1.8,0,3.5,0.2,5.2,0.7v-0.5c0-3-1.8-4.6-5.2-4.6c-1.8,0-3.5,0.4-5,1.2l-0.2,0.1l-0.7-1.6l0.1-0.1C213,11.9,215,11.5,217.1,11.4
    L217.1,11.4z M222.2,20c-1.7-0.4-3.5-0.7-5.3-0.7c-3.2,0-5.2,1.3-5.2,3.5v0.1c0,2.3,2.3,3.4,4.6,3.4c3.4,0,6-2,6-4.7V20L222.2,20z"
                />
            )}
            <path
                id="heart"
                fill={colorFinal}
                d="M34.9,9.2c1.1,1.1,1.1,2.9,0,4.1L16.3,31.8l-13-13c-2.8-2.7-3.9-6.8-3-10.6s4-6.8,7.8-7.8s7.8,0.1,10.6,3l1.6,1.8l-4.1,4.1l-1.7-1.7c-2-1.9-5.2-1.9-7.2,0.1s-2,5.2-0.1,7.2l8.9,8.9L30.9,9.2C32,8,33.8,8,34.9,9.2z
M40.8,3.4c4.2,4.2,4.2,11.1,0,15.4L24.2,35.4c-1.1,1.1-2.9,1.1-4.1,0l-2-2l18.5-18.5c2-2,2.1-5.3,0.1-7.3c-1-1-2.4-1.6-3.8-1.5
c-1.3,0.1-2.6,0.6-3.5,1.6L16.5,20.4l-7.3-7.3c-1.1-1.1-1.1-2.9,0-4s2.9-1.1,4,0l3.2,3.2l8.9-8.9C29.7-0.9,36.6-0.9,40.8,3.4z"
            />
        </svg>
    );
};

export default Logo;
